<template>
  <div class="auth-fb text-center">
    <div class="authfb-icon-area">
      <img
        class="authfb-icon"
        src="@/assets/icon/facebook-icon.png"
        alt="FB icon"
      />
      <img
        class="authfb-icon"
        src="@/assets/website-use/logo_word.png"
        alt="易團購 icon"
      />
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="line"></div>
      <p class="text-secondary fw-bolder tw-text-size18 authfb-text">
        facebook 免註冊用戶登入
      </p>
      <div class="line"></div>
    </div>
    <button class="tw-btn btn-primary" @click="authFb">facebook 登入</button>
  </div>
</template>

<script>
import { authFb } from '@/methods/API/fbApi'

export default {
  methods: {
    authFb() {
      // 紀錄 > 是從免註冊登入畫面走的
      window.localStorage.setItem('loginWithoutRegistration', "true")
      window.localStorage.setItem('identity', 'customer')
      authFb("login")
    },
  },
}
</script>